import { IPostWrapper } from '@/interfaces/wrapper';
import { BigThumbnailPostCard, HorizontalPostCard } from '../../PostCard';

const OneLargeFourSmall = ({ data }: IPostWrapper) => {
  return (
    <div className="flex flex-col lg:flex-row gap-3">
      <div className="w-full lg:w-[326px]">
        <BigThumbnailPostCard {...data?.[0]} />
      </div>

      <div className="flex-1 flex flex-col gap-3 justify-between">
        {data?.slice(1, 6).map((item, index) => {
          return <HorizontalPostCard {...item} key={index} />;
        })}
      </div>
    </div>
  );
};

export default OneLargeFourSmall;
